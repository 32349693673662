import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Button from 'components/CustomButtons/Button.js';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import VideoCall from '@material-ui/icons/VideoCall';


// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableColumn, tableData, resource, tableHeaderColor, tableColumnSize, onExport, onDelete, onAddVideo, onAddPhoto, handleInputChange, } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{width: tableColumnSize[key]}}                    
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((data, key) => {

              return (
                <TableRow key={key} className={classes.tableBodyRow}>               
                {tableColumn.map((prop, key) => {                             
                  return (            
                      <TableCell className={classes.tableCell} key={key}>     
                      {data[prop] && prop !== "ordem" ? data[prop] : null}                      
                      {prop === 'box_color' ? <div style={{marginTop: 10, backgroundColor: data["color"], width: 30, height: 30}}></div> : null}
                      {prop === 'banner' ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/banners/th/${data["name"]}`} alt="img"></img> : null}
                      {prop === 'campanha' ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/campanha/th/${data["name"]}`} alt="img"></img> : null}
                    
                      {prop === 'dayoffer' ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/dayoffer/th/${data["name"]}`} alt="img"></img> : null}
                      {prop === 'encarte' ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/encarte/th/${data["name"]}`} alt="img"></img> : null}
                      {prop === 'edit' ? <Link to={`/admin/${resource}/${data["id"]}`}><Button color="info" round><Edit /></Button></Link> : null}
                      {prop === 'delete' ? <Button color="danger" round onClick={() => onDelete(data["id"])}><Delete /></Button> : null}
                      {prop === 'export' ? <Button color="warning" round onClick={() => onExport(data["id"])}><FileCopy /></Button>: null}
                      {prop === 'video' ? <Button color="warning" round onClick={() => onAddVideo(data["id"], data["nome"])}><VideoCall /></Button> : null}
                      {prop === 'foto' ? <Button color="primary" round onClick={() => onAddPhoto(data["id"], data["nome"])}><AddAPhoto /></Button> : null}
                      {prop === 'ordem' ?  <input type="text" name="order" id="order" value={data["ordem"]} style={{width: '60px'}} onChange={(e) => handleInputChange(data["id"], e.target.value)} />  : null}
                      </TableCell>         
                  );            
                })}
                </TableRow>
              )
          })}            
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
};
