import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'

import { canvasPreview } from "components/CanvasPreview";

import 'react-image-crop/dist/ReactCrop.css'
import Button from 'components/CustomButtons/Button.js';


export default function CropImage({imagem, onChange, name, reset, uploadImage}) {

   const fileInput = useRef();
   const [aspect, setAspect] = useState();
   const imgRef = useRef(null);
   const previewCanvasRef = useRef(null);
   const [scale, setScale] = useState(1);
   const [rotate, setRotate] = useState(0);
   const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100
   })
   const [croppedImageUrl, setCroppedImageUrl] = useState();
   const [completedCrop, setCompletedCrop] = useState(); 
   const [src, setSrc] = useState();
   const [imageRef, setImageRef] = useState();

    function useDebounceEffect(
        fn,
        waitTime,
        deps
    ) {
        useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps)
        }, waitTime)
    
        return () => {
            clearTimeout(t)
        }
        }, deps)
    }

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect
    ) {
        return centerCrop(
        makeAspectCrop(
            {
            unit: "%",
            width: 90,
            },
            4/4,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
        );
    }

    function handleFile(e) {
        if (e.target.files && e.target.files.length > 0) {
        setCrop(undefined); // Makes crop preview update between images.
        const reader = new FileReader();
        reader.addEventListener("load", () =>
            setSrc(reader.result?.toString() || ""),
        );
        reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e) {
       
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
        
    }


    const onCancelCrop = () => {
        fileInput.current.value = "";
        setSrc(null);
        setCrop(null);
        //onChange(null);
        setCompletedCrop(null);
    }

   const onSaveCrop = () => {
       onCancelCrop();
   }

   async function getImage() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
     
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const reader = new FileReader()
    previewCanvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, name)
        }
    })



    
  }


  const dataURLtoFile = (dataurl, filename) => {

    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

 

    let croppedImage = new File([u8arr], filename, {type:mime});


    uploadImage(croppedImage);

  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );



   return (
      <>
      <input type='file' id='imagem' onChange={handleFile} ref={fileInput} reset={reset} />
      {src && (
        <>
       <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            src={src}
            onLoad={onImageLoad}
            style={{width: 250}}
          />
        </ReactCrop>
         <br />
    
         {!uploadImage ? <Button color="warning" round onClick={() => onSaveCrop()}>Recortar</Button> : null}
         <Button color="danger" round onClick={() => onCancelCrop()}>Cancelar</Button>  
         {uploadImage ? <Button color="success" round onClick={() => getImage()}>Upload Imagem</Button> : null}   
        </>
      )}    
      
      {!!completedCrop && (
        <><br />
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        
        </>
      )}

       {imagem && !src && !croppedImageUrl ? <><br /><img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/categories/rs/${imagem}`} width="250"  alt="img" /></> : null}
      
       <br />
       {croppedImageUrl && !src && uploadImage ? <Button color="success" round onClick={() => uploadImage()}>Upload Imagem</Button> : null}
      
      </>
   )
}