import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(styles);

export default function EditRegion() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    if (id !== "new") {
      setAction("update");
    }
  }, [id]);


  const handleSave = useCallback(async (e) => {

    try {

      e.preventDefault();
      setLoading(true);

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),
        telefone: Yup.string().required('Telefone é obrigatório !'),
        whatsapp: Yup.string().required('Whatsapp é obrigatório !'),
      });

      await schema.validate({ name, telefone, whatsapp }, {
        abortEarly: false,
      });

      const postData = {
        name,
        telefone,
        whatsapp
      }

      if (action === 'update') {
        postData.id = id;
      };

      await api.post('/regions', postData);

      setLoading(false);

      history.push('/admin/regions');

    }
    catch (e) {

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, [action, history, id, addToast, name, telefone, whatsapp]);

  const loadData = useCallback(async () => {
    try {
      const response = await api.get(`/regions/${id}`);

      setName(response.data.name);
      setTelefone(response.data.telefone);
      setWhatsapp(response.data.whatsapp);

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/regions");
    }
  }, [addToast, history, id]);


  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
  }, [action, id, loadData]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados da Região</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputMask mask="(99) 999999999" maskPlaceholder="" value={telefone} onChange={(event) => setTelefone(event.target.value)}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="telefone"
                        label="Telefone"
                        name="telefone"

                      />
                    </InputMask>
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12}>
                    <InputMask mask="(99) 999999999" maskPlaceholder="" value={whatsapp} onChange={(event) => setWhatsapp(event.target.value)}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="whatsapp"
                        label="Whatsapp"
                        name="whatsapp"

                      />
                    </InputMask>
                  </GridItem>

                </GridContainer>

              </CardBody>
              <CardFooter>
                {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
                  <CircularProgress />
                </Box>}
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
