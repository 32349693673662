import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function ProductsPage() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [paginas, setPaginas] = useState([]);
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [ativo, setAtivo] = useState('T');
  const [destaque, setDestaque] = useState('T');
  const [category, setCategory] = useState('T');
  const [subcategory, setSubcategory] = useState('T');
  const { addToast } = useToasts()
  const history = useHistory();


  const getData = useCallback(async () => {
    try {
      const products = [];
      setLoading(true);
      setData([]);

      const response = await api.post("/products/search", {
        filtros: {
          ativo,
          destaque,
          category_id: category,
          subcategory_id: subcategory,
          codigo,
          nome,
          pagina
        }
      });


      if (response.data) {
        response.data.products.map(p => {
          products.push({
            id: p.id,
            nome: p.nome,
            codigo: p.codigo,
            tipo: p.type === 'I' ? 'Individual' : 'Kit',
            category: p.category?.name,
            //subcategory: p.subcategory?.name,
            valor: new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(p.valor),
            ordem: p.ordem,
            ativo: p.ativo ? p.ativo = 'Sim' : 'Não',
            destaque: p.destaque ? p.destaque = 'Sim' : 'Não',
          })

          const pages = Math.floor(response.data.total / 100);
          const elem = [];

          for (let i = 1; i <= pages; i++) {
            elem.push(i);
          }

          setPaginas(elem);
          return p;
        })
      }


      setLoading(false);
      setData(products);
    }
    catch (e) {

      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [ativo, destaque, pagina, category, subcategory, codigo, nome, addToast]);


  useEffect(() => {
    getData();
  }, [pagina]);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/products/${id}`);
      setData(data => data.filter(d => d.id !== id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, [postDeleteAction]);



  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Produtos</h4>
            <p className={classes.cardCategoryWhite}>
              Produtos cadastrados no sistema.
            </p>
          </CardHeader>
          <CardBody>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="nome"
              label="Pesquise pelo nome do produto"
              name="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}

            />

            <TextField
              variant="outlined"
              style={{ marginTop: 10 }}
              fullWidth
              id="codigo"
              label="Pesquise pelo código do produto"
              name="codigo"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}

            />

            <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={20} />

            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 22 }}>
              <InputLabel id="category">Ativo</InputLabel>
              <Select
                labelId="ativo"
                label="ativo no site"
                variant="outlined"
                name="ativo"
                fullWidth
                value={ativo}
                onChange={(e) => setAtivo(e.target.value)}
              >
                <MenuItem key="T" value="T">Selecione</MenuItem>
                <MenuItem key="A1" value={true}>Sim</MenuItem>
                <MenuItem key="A2" value={false}>Não</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 22 }}>
              <InputLabel id="category">Destaque</InputLabel>
              <Select
                labelId="destaque no site"
                label="destaque"
                variant="outlined"
                name="destaque"
                fullWidth
                value={destaque}
                onChange={(e) => setDestaque(e.target.value)}
              >
                <MenuItem key="T" value="T">Selecione</MenuItem>
                <MenuItem key="D1" value={true}>Sim</MenuItem>
                <MenuItem key="D2" value={false}>Não</MenuItem>
              </Select>
            </FormControl>

            <Button color="primary" type="button" onClick={getData} style={{ marginTop: 20 }}>Filtrar</Button>

            <Link to="/admin/products/new"> <Button color="info" style={{ marginTop: 20 }}>Adicionar novo produto</Button></Link>

            <hr />


            <Select name="pagina" id="pagina" value={pagina} className="formControl" onChange={(e) => setPagina(e.target.value)}>
              {paginas.map(p => (
                <MenuItem value={p}>Pagina {p}</MenuItem>
              ))}
            </Select>


            {data && data.length > 0 ?
              <Table
                tableHeaderColor="primary"
                tableHead={['Codigo', 'Nome', 'Categoria', 'R$ Valor', 'Ativo', 'Destaque', '', '', '']}
                tableColumn={['codigo', 'nome', 'category', 'valor', 'ativo', 'destaque', 'foto', 'edit', 'delete']}
                tableColumnSize={['8%', '40%', '20%', '15%', '15%', '5%', '5%', '1%', '1%', '1%']}
                tableData={data}
                resource="products"
                onDelete={(id) => handleDelete(id)}
                onAddPhoto={(id, nome) => history.push(`/admin/products/images/${id}`)}
                handleInputChange={(id, valor) => {
                  const products = [...data];
                  const product = products.find(p => p.id === id);
                  product.ordem = valor;
                  setData(products);
                }}
              /> : isLoading ? (
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <p style={{ marginTop: 20 }}>Nenhum produto cadastrado no momento.</p>
              )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
