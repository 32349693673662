
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import Region from '../../components/Region/Region';
import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Customers() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [region, setRegion] = useState('T');
  const { addToast } = useToasts()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tipo, setTipo] = useState('PF');
  const [document, setDocument] = useState('');
  const [ordem, setOrdem] = useState('ordem_alfabetica');

  const [pagina, setPagina] = useState(1);
  const [paginas, setPaginas] = useState([]);



  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.post("/customers/search", {
        filtros: {
          name,
          email,
          ordem,
          region,
          pagina,
          document,
          tipo
        }
      });

      const result = response.data;
      const customers = [];

      result.customers.map(d => {
        customers.push({
          id: d.id,
          name: d.name,
          type: d.type,
          email: d.email,
          razao_social: d.razao_social,
          document: d.document,
          created_at: new Date(d.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        });
        return d;
      })


      const pages = Math.floor(result.total / 100);
      const elem = [];

      for (let i = 1; i <= pages; i++) {
        elem.push(i);
      }

      setPaginas(elem);

      setData(customers);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [name, email, ordem, addToast, tipo, document, region, pagina]);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/customers/${id}`);
      setData(data => data.filter(d => d.id !== id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, region]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, [postDeleteAction]);


  useEffect(() => {
    getData();
  }, [pagina]);


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Clientes</h4>
            <p className={classes.cardCategoryWhite}>
              Clientes cadastrados no site.
            </p>
          </CardHeader>
          <CardBody>


            <Region value={region} handleChangeValue={(value) => setRegion(value)} marginTop={20} />

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 20 }}>
                  <InputLabel id="tipo_cliente">Tipo Cliente</InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    id="tipo"
                    label="tipo cliente"
                    name="tipo"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                  >
                    <MenuItem key="PF" value="PF">Pessoa Física</MenuItem>
                    <MenuItem key="PJ" value="PJ">Pessoa Jurídica</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>

                <InputMask mask={tipo === "PF" ? "999.999.999-99" : "99.999.999/9999-99"} maskChar=" " onChange={(e) => setDocument(e.target.value)} >
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Número documento"
                    variant="outlined"
                    style={{ marginTop: 20 }}
                    fullWidth />
                </InputMask>

              </GridItem>
            </GridContainer>

            <TextField

              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="Pesquise pelo nome"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}

            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="Pesquise pelo email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />


            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 15 }}>
              <InputLabel id="ordem_label">Ordenar por:</InputLabel>
              <Select
                labelId="ordem"
                label="ordenar por:"
                variant="outlined"
                name="ordem"
                fullWidth
                value={ordem}
                onChange={(e) => setOrdem(e.target.value)}
              >
                <MenuItem value={"ordem_alfabetica"}>
                  <em>Ordem alfabética</em>
                </MenuItem>
                <MenuItem value={"mais_recentes"}>
                  <em>Mais recentes</em>
                </MenuItem>
                <MenuItem value={"mais_antigos"}>
                  <em>Mais antigos</em>
                </MenuItem>

              </Select>
            </FormControl>

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Filtrar</Button>

            <br /><br />
            <Link to="/admin/customers/new"> <Button color="info">Adicionar novo cliente</Button></Link>

            <hr />
            <Select name="pagina" id="pagina" value={pagina} className="formControl" onChange={(e) => setPagina(e.target.value)}>
              {paginas.map(p => (
                <MenuItem value={p}>Pagina {p}</MenuItem>
              ))}
            </Select>


            {loading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Nome', 'Tipo', 'Cpf/Cnpj', 'Razão Social', 'Cadastrado em ', '', '']}
                  tableColumn={['name', 'type', 'document', 'razao_social', 'created_at', 'edit', 'delete']}
                  tableColumnSize={['30%', '10%', '20%', '30%', '30%', '1%', '1%']}
                  tableData={data}
                  resource="customers"
                  onDelete={(id) => handleDelete(id)} /> : <p style={{ marginTop: 20 }}>Nenhum cliente cadastrado no momento.</p>}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
