// @material-ui/icons

import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import Loyalty from '@material-ui/icons/Loyalty';

// core components/views for Admin layout
import AdminPage from '../pages/Admin';
import CategoriesPage from '../pages/Categories';
import SubcategoriesPage from '../pages/Subcategories';
import StatsPage from '../pages/Stats';
import BannersPage from '../pages/Banners';
import CampanhasPage from '../pages/Campanhas';
import ProductsPage from '../pages/Products';
import TextsPage from '../pages/Texts';
import CustomersPage from '../pages/Customers';
import NotificationsPage from '../pages/Notifications';
import ConfigurationsPage from '../pages/Configurations';
import RegionsPage from '../pages/Regions';
import EventsPage from '../pages/Events';
import VideosPage from '../pages/Videos';
import DayOfferPage from '../pages/DayOfferPage';
import DayOfferPageSnack from '../pages/DayOfferPageSnack';
import DayOfferAtacadoPage from '../pages/DayOfferAtacadoPage';
import EncartePage from '../pages/EncartePage';
import PendingCustomersPage from '../pages/PendingCustomers';

const menuRoutes = [ 
  {
    path: '/administradores',
    name: 'Administradores',
    icon: Person,
    component: AdminPage,
    layout: '/admin',
  },

  {
    path: '/stats',
    name: 'Estatísticas Acesso',
    icon: Assignment,
    component: StatsPage,
    layout: '/admin',
  },
  {
    path: '/clientes',
    name: 'Clientes',
    icon: Assignment,
    component: CustomersPage,
    layout: '/admin',
  },

  {
    path: '/clientes_pendentes',
    name: 'Clientes Pendentes',
    icon: Assignment,
    component: PendingCustomersPage,
    layout: '/admin',
  },
  {
    path: '/categories',
    name: 'Categorias',
    icon: Assignment,
    component: CategoriesPage,
    layout: '/admin',
  },
  {
    path: '/regions',
    name: 'Regiões',
    icon: Assignment,
    component: RegionsPage,
    layout: '/admin',
  },
  {
    path: '/bannersapp',
    name: 'Banners',
    icon: Assignment,
    component: BannersPage,
    layout: '/admin',
  },
  {
    path: '/campanhas',
    name: 'Campanhas',
    icon: Assignment,
    component: CampanhasPage,
    layout: '/admin',
  },
  {
    path: '/products',
    name: 'Produtos',
    icon: Loyalty,
    component: ProductsPage,
    layout: '/admin',
  },
  {
    path: '/dayofferpage_snack',
    name: 'Oferta do Dia / Snacks',
    icon: Loyalty,
    component: DayOfferPageSnack,
    layout: '/admin', 
  },

  {
    path: '/dayofferpage',
    name: 'Oferta do Dia / Varejo',
    icon: Loyalty,
    component: DayOfferPage,
    layout: '/admin', 
  },

  {
    path: '/dayofferatacadopage',
    name: 'Oferta do Dia / Atacado',
    icon: Loyalty,
    component: DayOfferAtacadoPage,
    layout: '/admin',
  },
  {
    path: '/encarte',
    name: 'Encarte',
    icon: Loyalty,
    component: EncartePage,
    layout: '/admin',
  },
  {
    path: '/events',
    name: 'Eventos',
    icon: Loyalty,
    component: EventsPage,
    layout: '/admin',
  },
  {
    path: '/videos',
    name: 'Videos',
    icon: Loyalty,
    component: VideosPage,
    layout: '/admin',
  },
  {
    path: '/configurations',
    name: 'Configurações',
    icon: Loyalty,
    component: ConfigurationsPage,
    layout: '/admin',
  },

  {
    path: '/notifications',
    name: 'Notificações',
    icon: Loyalty,
    component: NotificationsPage,
    layout: '/admin',
  },
  {
    path: '/texts',
    name: 'Textos Site',
    icon: Assignment,
    component: TextsPage,
    layout: '/admin',
  },

];

export default menuRoutes;
