
import React, { useState, useEffect, useCallback} from 'react';
import 'rsuite/dist/styles/rsuite-default.css';

import { Link, useParams, useHistory } from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Delete from '@material-ui/icons/Delete';

import CropImage from 'components/CropImage/CropImage';

import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';



import api from '../../services/api';

const useStyles = makeStyles({
  root: {
    maxWidth: 350,    
  },
  destaque: {
    maxWidth: 350,   
    backgroundColor: '#FAFA'
  },
  media: {
    height: 350,
  },
  boxupload: {
    lineHeight: '150px',
    backgroundColor: '#CACACA'
  }
});

export default function ProductImagesPage() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState('');
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const { addToast } = useToasts();
  const { id } = useParams();   

  const getData = useCallback(async () => {

    try {

      setLoading(true);
      const response = await api.get(`/products/images/${id}`);     
      setData(response.data);
      setLoading(false);
     
    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        

      setLoading(false);
    } 
  },[addToast, id]);

  const uploadImage = useCallback(async(file) => {

    try { 

      setLoading(true);
      const formData = new FormData();

      console.log(file);

      if (file){
         formData.append('images', file);
      }
 
      await api.post(`/products/images/${id}`, formData);
      setLoading(false);
      setTimeout(() => {
        getData();
      },1000);
     

    }
    catch(e){}

  },[getData, id]);


  const postDeleteAction =  useCallback(async (id) => {
    try {
        setLoading(true);
        await api.delete(`/products/images/${id}`);
        setData(data => data.filter(d => d.id !== id));
        setLoading(false);
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });    
      setLoading(false);    
    }
  },[addToast]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover esta imagem ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[postDeleteAction]);


  const handleSetDestaque =  useCallback(async (image_id) => {
    try {
        await api.patch(`/products/images/destaque/${image_id}`, {
          destaque: true
        });
        getData();
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });        
    }
   },[addToast, getData]);


  const getProductInfo = useCallback(async () => {    
    try {
      const response = await api.get(`/products/${id}`);
      setCodigo(response.data.codigo);   
      setNome(response.data.nome);
    }
    catch(e){
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      
      history.push("/admin/products");
    }
  }, [addToast, history, id]);

  useEffect(() => {
    getProductInfo();
    getData();
  }, [getData, getProductInfo]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Imagens de produto</h4>
            <p className={classes.cardCategoryWhite}>
              Insira as imagens do produto <strong>{nome} - COD: {codigo}</strong>
            </p>
          </CardHeader>
         
          <CardBody>  

            
           {!isLoading ? <CropImage imagem={''} onChange={(file) => { setSelectedFile(file); console.log(file); }} name="image_produto.jpg" uploadImage={(file) => uploadImage(file)} /> : null}
          
            <br /><br />

            <GridContainer>
              {!isLoading ? 
              <>
              {data && data.map((data, index) => {  
                return (                                
                  <GridItem key={data.id} xs={12} sm={12} md={3}>            
                  <Card className={classes.root}>                               
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/products/rs/${data.image_name}`}                        
                    />                    
                    </CardActionArea>
                    <CardActions>
                      <Button color="danger" round onClick={() => handleDelete(data.id)} simple><Delete /> Remover</Button>     
                    </CardActions>       
                     
                  
                 </Card>
                 
                 </GridItem>                
                )
               })}
               </> : isLoading ? (
                  <Box mt={6}>
                  <CircularProgress />
                  </Box>               
                ) : (           
                  <p style={{marginTop: 20}}>Nenhuma imagem inserida no momento.</p>
                )} 

          </GridContainer>
         
            <br />
            <Link to="/admin/products"><Button color="primary" type="button">Voltar</Button></Link>
           
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
