
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import Region from '../../components/Region/Region';
import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function PendingCustomersPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [region, setRegion] = useState('T');
  const { addToast } = useToasts()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tipo, setTipo] = useState('PF');
  const [document, setDocument] = useState('');
  const [ordem, setOrdem] = useState('ordem_alfabetica');

  const [pagina, setPagina] = useState(1);
  const [paginas, setPaginas] = useState([]);



  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.post("/customers/search", {
        filtros: {
          name,
          email,
          ordem,
          region,
          pagina,
          document,
          pending: true
        }
      });

      const result = response.data;
      const customers = [];

      result.customers.map(d => {
        customers.push({
          id: d.id,
          name: d.name,
          type: d.type,
          email: d.email,
          razao_social: d.razao_social,
          document: d.document,
          created_at: new Date(d.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        });
        return d;
      })


      const pages = Math.floor(result.total / 100);
      const elem = [];

      for (let i = 1; i <= pages; i++) {
        elem.push(i);
      }

      setPaginas(elem);

      setData(customers);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [name, email, ordem, addToast, tipo, document, region, pagina]);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/customers/${id}`);
      setData(data => data.filter(d => d.id !== id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, region]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, [postDeleteAction]);

  const handleExportCustomer = useCallback(async (id) => {
      
    const response = await api.get(`/customers/exportCustomer/${id}`, { responseType: 'blob' });          
            
    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = window.document.createElement('a'); 
    link.href = url;

    link.setAttribute('download', `cliente_${id}.pdf`); 
    window.document.body.appendChild(link);
    link.click();
  }, []);


  useEffect(() => {
    getData();
  }, [pagina]);


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Clientes Pendentes</h4>
            <p className={classes.cardCategoryWhite}>
              Clientes cadastrados no aplicativo que estão pendentes.
            </p>
          </CardHeader>
          <CardBody>


            <Select name="pagina" id="pagina" value={pagina} className="formControl" onChange={(e) => setPagina(e.target.value)}>
              {paginas.map(p => (
                <MenuItem value={p}>Pagina {p}</MenuItem>
              ))}
            </Select>


            {loading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Nome', 'Tipo', 'Cpf/Cnpj', 'Razão Social', 'Cadastrado em ', '', '', '']}
                  tableColumn={['name', 'type', 'document', 'razao_social', 'created_at', 'edit', 'delete', 'export']}
                  tableColumnSize={['30%', '10%', '20%', '30%', '30%', '1%', '1%', '1%']}
                  tableData={data}
                  resource="customersPending"
                  onExport={(id) => handleExportCustomer(id)}
                  onDelete={(id) => handleDelete(id)} /> : <p style={{ marginTop: 20 }}>Nenhum cliente pendente no momento.</p>}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
