/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';
import EditCategory from "pages/Categories/edit";

const useStyles = makeStyles(styles);

export default function Event({value, handleChangeValue, marginTop}) {

  useEffect(() => {
       loadEvents();
  },[]); 

  const [events, setEvents] = useState([]);

  const loadEvents = useCallback(async() => {
    try {
      const response = await api.get("/events");
      setEvents(response.data.events ?? []);
    }
    catch(e){

    }
  },[]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
    <InputLabel id="category">Evento</InputLabel> 
         
       <Select 
            labelId="event_id"
            label="Evento"
            variant="outlined"                  
            name="event_id"
            fullWidth
            value={value ? value : 'T'}
            onChange={(e) => handleChangeValue(e.target.value)}               
            >    
            <MenuItem key="T" value="T">Geral / Institucional</MenuItem>                                   
            {events.map(event => {
               return <MenuItem key={event.id} value={event.id}>{event.title}</MenuItem>        
            })}                               
      </Select>
   </FormControl>
  );
}
