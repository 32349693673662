import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'

import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { useForm, Controller } from 'react-hook-form'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Region from '../../components/Region/Region';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);



export default function EditCustomer() {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('create');
  const [region, setRegion] = useState('T');
  const [pending, setPending] = useState(0);
  const [actualStatus, setActualStatus] = useState(0);

  const [tipo, setTipo] = useState('PF');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();

  const customerFormSchema = yup.object().shape({
    nome: yup.string().required('Informe o seu nome'),
    document: yup.string().required('Informe o número do documento'),
  });

  const { register, handleSubmit, reset, formState, control, setValue } = useForm({
    resolver: yupResolver(customerFormSchema)
  });


  const getCep = useCallback(async (cep) => {
    try {
      const response = fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => response.json())
        .then(data => {

          if (data) {
            setValue('cep', data.cep);
            setValue('cidade', data.localidade);
            setValue('bairro', data.bairro);
            setValue('estado', data.uf);
            setValue('endereco', data.logradouro);

          }
        })
    }
    catch (e) { }
  }, []);

  const handleSave = useCallback(async (values) => {

    try {



      setLoading(true);

      const senha = values.document.replace(/[^0-9]/g, '');

      const data = {
        name: values.nome,
        type: tipo,
        document: values.document,
        email: values.email,
        cep: values.cep,
        endereco: values.endereco,
        bairro: values.bairro,
        cidade: values.cidade,
        estado: values.estado,
        tel_01: values.tel_01,
        code: values.code,
        razao_social: values.razao_social,
        password: senha,
        region: region !== 'T' ? region : null,
        pending
      }

      if (action === 'update') {
        data.id = id;
      };

      if (action === "update") {
        await api.post('/customers/update', data);
      }
      else {
        await api.post('/customers', data);
      }


      if (actualStatus === 1){

         history.push('/admin/clientes_pendentes');
      }
      else{
          history.push('/admin/clientes');    
      }

    }
    catch (e) {

      setLoading(false);

      if (e instanceof yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, [action, addToast, history, id, tipo, region, actualStatus, pending]);


  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/customers/${id}`);

      setValue('nome', response.data.name);
      setValue('tipo', response.data.type);
      setValue('document', response.data.document);
      setValue('email', response.data.email);
      setValue('tel_01', response.data.tel_01);
      setValue('cep', response.data.cep);
      setValue('cidade', response.data.cidade);
      setValue('bairro', response.data.bairro);
      setValue('estado', response.data.estado);
      setValue('endereco', response.data.endereco);
      setValue('code', response.data.code);
      setValue('razao_social', response.data.razao_social);

      setTipo(response.data.type);
      setPending(response.data.pending);
      setActualStatus(response.data.pending);



      if (response.data.region) {
        setRegion(response.data.region.id);
      }
      setLoading(false);
    }
    catch (e) {
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
      history.push("/admin/customers");
    }
  }, [addToast, history, id, setValue]);

  useEffect(() => {
    if (id !== 'new') {
      setAction('update');
    }
  }, [id]);

  useEffect(() => {
    if (action === 'update') {
      loadData(id);
    }
  }, [action, id, loadData]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSubmit(handleSave)}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados do cliente</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>

                    <GridContainer>

                      <GridItem xs={12} sm={12} md={12}>
                        <Region value={region} handleChangeValue={(value) => setRegion(value)} marginTop={15} />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>

                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="code"
                          label="Código"
                          name="code"
                          autoFocus
                          {...register('code')}

                        />
                      </GridItem>
                    </GridContainer>


                    <GridContainer>

                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="nome"
                          label="Nome"
                          name="nome"
                          autoFocus
                          {...register('nome')}

                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 15 }}>
                          <InputLabel id="tipo_cliente">Tipo Cliente</InputLabel>
                          <Select
                            variant="outlined"
                            fullWidth
                            id="tipo"
                            label="tipo cliente"
                            name="tipo"
                            value={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                          >
                            <MenuItem key="PF" value="PF">Pessoa Física</MenuItem>
                            <MenuItem key="PJ" value="PJ">Pessoa Jurídica</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>


                    {tipo === 'PJ' && <GridContainer>
                      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="razao_social"
                          style={{ marginTop: 10 }}
                          label="Razão Social"
                          name="razao_social"
                          {...register(`razao_social`)}
                        />
                      </GridItem>
                    </GridContainer>}

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Controller
                          name={"document"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <InputMask mask={tipo === "PF" ? "999.999.999-99" : "99.999.999/9999-99"} maskChar=" " onChange={onChange} value={value}  >
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  label="Número documento"
                                  variant="outlined"
                                  style={{ marginTop: 20 }}
                                  fullWidth />
                              </InputMask>
                            </>
                          )}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          style={{ marginTop: 20 }}
                          {...register(`email`)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <Controller
                          name={"cep"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <InputMask mask="99999-999" maskChar=" " onChange={onChange} onBlur={(e) => getCep(e.target.value)} value={value}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  label="CEP"
                                  variant="outlined"
                                  style={{ marginTop: 10 }}
                                  fullWidth />
                              </InputMask>
                            </>
                          )}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="endereco"
                          style={{ marginTop: 20 }}
                          label="Endereço"
                          name="endereco"
                          {...register(`endereco`)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="bairro"
                          style={{ marginTop: 10 }}
                          label="Bairro"
                          name="bairro"
                          {...register(`bairro`)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="cidade"
                          style={{ marginTop: 10 }}
                          label="Cidade"
                          name="cidade"
                          {...register(`cidade`)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="estado"
                          style={{ marginTop: 10 }}
                          label="Estado / UF"
                          name="estado"
                          {...register(`estado`)}
                        />
                      </GridItem>
                    </GridContainer>



                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <Controller
                          name={"tel_01"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <InputMask mask={"999999999999"} maskPlaceholder="" alwaysShowMask={false} onChange={onChange} value={value}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  label="Telefone / Login (sem espacos ou tracos, apenas os numeros)"
                                  variant="outlined"
                                  style={{ marginTop: 15 }}
                                  fullWidth />
                              </InputMask>
                            </>
                          )}
                        />

                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 15 }}>
                          <InputLabel id="pending">Cadastro liberado</InputLabel>
                          <Select
                            variant="outlined"
                            fullWidth
                            id="pending"
                            label="cadastro liberado"
                            name="pending"
                            value={pending}
                            onChange={(e) => setPending(e.target.value)}
                          >
                            <MenuItem key="1" value="1">Pendente</MenuItem>
                            <MenuItem key="0" value="0">Liberado</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>


                    <Button color="info" type="submit" style={{ marginTop: 20 }}>Salvar</Button>
                  </>
                )}

                <br /><br />
                
                {actualStatus === 1 ? <Link to="/admin/clientes_pendentes"><Button color="primary" type="button">Voltar</Button></Link> : <Link to="/admin/clientes"><Button color="primary" type="button">Voltar</Button></Link>}

              </CardBody>
              <CardFooter>

              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
