/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Region({ value, handleChangeValue, marginTop }) {

  useEffect(() => {
    loadData();
  }, []);

  const [data, setData] = useState([]);

  const loadData = useCallback(async () => {
    try {
      const response = await api.get("/regions");
      setData(response.data);
    }
    catch (e) {

    }
  }, []);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: marginTop }}>
      <InputLabel id="regiao">Região</InputLabel>

      <Select
        labelId="category"
        label="Região"
        variant="outlined"
        name="region"
        fullWidth
        value={value ? value : 'T'}
        onChange={(e) => handleChangeValue(e.target.value)}
      >
        <MenuItem key="T" value="T">Selecione</MenuItem>
        {data.map(d => {
          return <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
        })}
      </Select>
    </FormControl>
  );
}
