import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'  

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';


const editorConfiguration = { 
    toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
};

const useStyles = makeStyles(styles);

export default function EditProduct() {
  const [action, setAction] = useState('create');
  const [, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState(''); 
  const [unidade, setUnidade] = useState(''); 
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [valorAtacado, setValorAtacado] = useState('');
  const [valorde, setValorde] = useState('');
  const [ativo, setAtivo] = useState(true);  
  const [promocao, setPromocao] = useState(false);  
  const [superoferta, setSuperOferta] = useState(false); 
  const [category, setCategory] = useState('T');
  const [subcategory, setSubcategory] = useState('T');
   
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles(); 

  useEffect(() => {
    if (id !== "new"){
      setAction("update");
    }
  },[id]);


  const handleSave = async (e) => {

    try {  

      e.preventDefault();

      const codigo = e.target.codigo.value;
      const nome = e.target.nome.value;
      const category_id = category !== 'T' ? category : '';  
      const subcategory_id = subcategory !== 'T' ? subcategory : '';       
      const valor = e.target.valor.value;  
      const valorde = e.target.valorde.value;  
      const valoratacado = e.target.valoratacado.value;  
      const unidade = e.target.unidade.value;  
     
      const schema = Yup.object().shape({
        codigo: Yup.string().required('Código do produto é obrigatório'),      
        nome: Yup.string().required('Nome do produto é obrigatório'),
        category_id: Yup.string().required('Informe a categoria'),                
        descricao: Yup.string().required('Informe a descrição do produto'),
        valor: Yup.string().required('Informe o valor'),      
        unidade: Yup.string().required('Informe a apresentação / unidade do produto'),
      });         

      await schema.validate({codigo, nome, category_id, descricao, valor, unidade, subcategory_id}, {
        abortEarly: false
      });
  
      const postData = {         
        codigo,
        nome,
        category: category_id,
        subcategory: subcategory_id ? subcategory_id : null,
        valor: valor.replace(",","."),
        valorde: valorde ? valorde.replace(",",".") : null,
        valoratacado: valoratacado ? valoratacado.replace(",",".") : null,
        descricao,       
        ativo,
        promocao,
        superoferta,
        unidade,
       };
      
       
      if (action === 'update'){
        postData.id = id;
      }
  
      await api.post(`/products`, postData);   

      history.push('/admin/products');
   
    }
    catch(e){ 

      setLoading(false);

  
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });         
  
    }
  };

  const loadData = useCallback(async () => {   

    try {

      setLoading(true);
      
      const response = await api.get(`/products/${id}`);

      setCodigo(response.data.codigo);   
      setNome(response.data.nome);      
      setCategory(response.data.category?.id);
      setSubcategory(response.data.subcategory?.id);
      setUnidade(response.data.unidade);

      setValor(response.data.valor);
      setValorde(response.data.valorde);
      setValorAtacado(response.data.valoratacado);
      setDescricao(response.data.descricao);
      setAtivo(response.data.ativo);
      setPromocao(response.data.promocao);
      setSuperOferta(response.data.superoferta);
      
      setLoaded(true);
      setLoading(false);
      
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      

      history.push("/admin/products");

    }
  },[addToast, history, id]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
    else{
      setLoaded(true);
      setLoading(false);
    }
  },[action, id, loadData]);



  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>        
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do produto</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>           
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{marginTop: 20}}>    
                    <TextField
                        variant="outlined"              
                        fullWidth
                        id="codigo" 
                        label="Código referência do produto"
                        name="codigo"
                        value={codigo}
                        style={{marginBottom: 5}} 
                        required
                        onChange={(e) => setCodigo(e.target.value)}            
                    />  

                    <TextField
                        variant="outlined"
                        margin="normal"       
                        fullWidth
                        id="nome" 
                        label="Nome do produto"
                        name="nome"
                        value={nome}
                        required
                        onChange={(e) => setNome(e.target.value)}            
                    />             
          
                    <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={15} />


                    <CurrencyTextField
                        style={{marginTop: 25}} 
                        label="Valor de"                       
                        value={valorde}
                        currencySymbol="R$"
                        variant="outlined"
                        margin="normal"       
                        fullWidth
                        textAlign="left"              
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator=""
                        name="valorde"
                        onChange={(event, value)=> setValorde(value)}
                    />
                    
                    <CurrencyTextField
                        style={{marginTop: 25}} 
                        label="Valor por"                       
                        value={valor}
                        currencySymbol="R$"
                        variant="outlined"
                        margin="normal"       
                        fullWidth
                        textAlign="left"              
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator=""
                        name="valor"
                        onChange={(event, value)=> setValor(value)}
                    />


                  <CurrencyTextField
                        style={{marginTop: 25}} 
                        label="Valor atacado"                       
                        value={valorAtacado}
                        currencySymbol="R$"
                        variant="outlined"
                        margin="normal"       
                        fullWidth
                        textAlign="left"              
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator=""
                        name="valoratacado"
                        onChange={(event, value)=> setValorAtacado(value)}
                    />

                    <TextField
                        style={{marginTop: 15, marginBottom: 5}} 
                        variant="outlined"              
                        fullWidth
                        id="unidade" 
                        label="Unidade"
                        name="unidade"
                        value={unidade}
                      
                        required
                        onChange={(e) => setUnidade(e.target.value)}            
                    />  
                        
                    <p style={{marginTop: 20}}>Descrição do produto</p>
                
                    <CKEditor
                            editor={ Editor }
                            config={ editorConfiguration }
                            data={descricao}
                            onReady={(editor) => {       
                              editor.editing.view.change((writer) => {
                              writer.setStyle(
                                  "height",
                                  "400px",
                                  editor.editing.view.document.getRoot()
                              );
                              });
                            }}    
                            onChange={ ( event, editor ) => {
                              const data = editor.getData();                      
                              setDescricao(data);
                          } }           
                    />

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                        <InputLabel id="category">Ativo no site?</InputLabel>             
                        <Select labelId="ativo"
                                label="ativo no site"
                                variant="outlined"                  
                                name="ativo"
                                fullWidth
                                value={ativo}
                                onChange={(e) => setAtivo(e.target.value)}              
                                >    
                                <MenuItem key="A1" value={true}>Sim</MenuItem> 
                                <MenuItem key="A2" value={false}>Não</MenuItem>                                                                 
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                        <InputLabel id="category">Promoção?</InputLabel>             
                        <Select 
                                  labelId="promocao"
                                  label="Promoção"
                                  variant="outlined"                  
                                  name="promocao"
                                  fullWidth
                                  value={promocao}
                                  onChange={(e) => setPromocao(e.target.value)}              
                                  >    
                                  <MenuItem key="D1" value={true}>Sim</MenuItem> 
                                  <MenuItem key="D2" value={false}>Não</MenuItem>                                                                 
                        </Select>
                    </FormControl>                

                     <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                        <InputLabel id="category">Super Oferta?</InputLabel>             
                        <Select 
                                  labelId="superoferta"
                                  label="Super oferta"
                                  variant="outlined"                  
                                  name="superoferta"
                                  fullWidth
                                  value={superoferta}
                                  onChange={(e) => setSuperOferta(e.target.value)}              
                                  >    
                                  <MenuItem key="D1" value={true}>Sim</MenuItem> 
                                  <MenuItem key="D2" value={false}>Não</MenuItem>                                                                 
                        </Select>
                    </FormControl>                                                       
                  </GridItem>
                </GridContainer>
                      
            </CardBody>

            {loaded ? <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter> : null}

          </form>
         </Card>
        </GridItem>        
      </GridContainer>
    </div>
  );
}
