import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Regions() {

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { addToast } = useToasts();



  const getData = useCallback(async () => {
    try {
      setLoading(true);
      setData([]);

      const response = await api.get("/regions");
      setData(response.data);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    getData();
  }, [getData]);


  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/regions/${id}`);
      setData(data => data.filter(d => d.id !== id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, [postDeleteAction]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Regiões</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de regiões cadastradas no sistema.
            </p>
          </CardHeader>
          <CardBody>

            <Link to="/admin/regions/new"> <Button color="info">Adicionar nova categoria</Button></Link>

            <hr />

            {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Nome', '', '']}
              tableColumn={['name', 'edit', 'delete']}
              tableColumnSize={['60%', '1%', '1%']}
              tableData={data}
              resource="regions"
              onDelete={(id) => handleDelete(id)}

            /> : isLoading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <p style={{ marginTop: 20 }}>Nenhuma região cadastrada no momento.</p>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
