import { Switch } from 'react-router-dom';

import Login from 'pages/Login';
import TermoUso from 'pages/TermoUso';
import Route from './route';

import Admin from '../layouts/Admin';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/termouso" exact component={TermoUso} /> 
    <Route path="/admin" isPrivate component={Admin} />
  </Switch> 
);
 
export default Routes;
