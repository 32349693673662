import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Region from 'components/Region/Region';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress, TextareaAutosize } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(styles);

export default function EditNotification() {
  const [action, setAction] = useState('create');
  const [region, setRegion] = useState('T');

  const [titulo, setTitulo] = useState('');
  const [texto, setTexto] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    if (id !== "new") {
      setAction("update");
    }
  }, [id]);


  const handleSave = useCallback(async (e) => {

    try {

      e.preventDefault();
      setLoading(true);

      const schema = Yup.object().shape({
        titulo: Yup.string().required('Titulo é obrigatório !'),
        texto: Yup.string().required('Texto é obrigatório !'),
      });

      await schema.validate({ titulo, texto }, {
        abortEarly: false,
      });

      const postData = {
        titulo,
        texto,
        region: region !== 'T' ? region : null
      }

      if (action === 'update') {
        postData.id = id;
      };

      await api.post('/notifications', postData);

      setLoading(false);

      history.push('/admin/notifications');

    }
    catch (e) {

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, [titulo, texto, action, history, id, region, addToast]);

  const loadData = useCallback(async () => {
    try {

      const response = await api.get(`/notifications/${id}`);
      setTitulo(response.data.titulo);
      setTexto(response.data.texto);

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/notifications");

    }
  }, [addToast, history, id]);


  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
  }, [action, id, loadData]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados da Categoria</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Region value={region} handleChangeValue={(value) => setRegion(value)} marginTop={15} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="titulo"
                      label="Titulo"
                      name="titulo"
                      value={titulo}
                      onChange={(e) => setTitulo(e.target.value)}
                      autoFocus
                    />

                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      minRows={10}
                      variant="outlined"
                      margin="normal"
                      multiline={true}
                      rows={5}
                      required
                      fullWidth
                      id="texto"
                      label="Texto"
                      name="texto"
                      value={texto}
                      onChange={(e) => setTexto(e.target.value)}
                    />

                  </GridItem>
                </GridContainer>

                {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
                  <CircularProgress />
                </Box>}

                <br /><br />
                <Link to="/admin/notifications"><Button color="info" type="button">Voltar</Button></Link>

              </CardBody>


            </form>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
