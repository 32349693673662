import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Event from 'components/Event/Event.js';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(styles);

export default function EditVideo({location}) {

  const query = new URLSearchParams(location.search);
  const event_id = query.get('ev');
  const [action, setAction] = useState('create');
  const [title, setTitle] = useState('');
  const [event, setEvent] = useState(event_id ? event_id : null);
  const [videoid, setVideoid] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();   




  const classes = useStyles();

  const YouTubeGetID = (url) => {
    let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return regex.exec(url)[3];
  }


  useEffect(() => {
    if (id !== "new"){
      setAction("update");
    }
  },[id]);


  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();  
      setLoading(true);

      const postData = {
        title,
        videoid,
        event_id: event
      }
     
      if (action === 'update'){
          postData.id = id;
      };

      if (action === 'update'){
        await api.put('/videos/'+id, postData);
      }
      else{
        await api.post('/videos', postData);
      }

      setLoading(false);
   
      history.push('/admin/videos');
   
    }
    catch(e){ 

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
            
    }
  },[action, history, id, title, event, videoid, addToast]);

  const loadData = useCallback(async () => {    
    try {

      const response = await api.get(`/videos/${id}`);

      setTitle(response.data.title);
      setVideoid(response.data.videoid);    
      setEvent(response.data.eventId);



    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/videos");
    }
  },[addToast, history, id]);


  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action, id, loadData]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do Video</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>

            <Event value={event} handleChangeValue={(value) => setEvent(value)} marginTop={15} />

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="title"
                      label="Titulo"
                      name="name"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      autoFocus
                  />                                    
              
               </GridItem>               
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="videoid"
                      label="You tube URL"
                      name="videoid"
                      value={videoid}
                      onChange={(e) => setVideoid(e.target.value)}
                      autoFocus
                  />                                    
              
               </GridItem>               
              </GridContainer>

              {action === 'update' && videoid && <iframe width="420" height="315"
src={`https://www.youtube.com/embed/${YouTubeGetID(videoid)}`}>
</iframe>}
             
            </CardBody>
            <CardFooter>
              {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
             <CircularProgress />
            </Box>}
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
