import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import FileUploader from 'components/FileUploader/FileUploader';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditCampanha() {
  const [action, setAction] = useState('create');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [url, setUrl] = useState('');
  const [buttontext, setButtontext] = useState('');
  const [imagem, setImagem] = useState('');
  const [removerImagem, setRemoverImagem] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = async (e) => {

    try { 

      e.preventDefault();    
      setLoading(true);
         
      if (!selectedFile && action!='update'){
        setLoading(false);
        addToast('É necessário ao menos informar a imagem', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      if (!title){
        setLoading(false);
        addToast('É necessário ao menos informar a identificação ', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const formData = new FormData();
    
      if (selectedFile){
          formData.append('image', selectedFile);
      }

      if (action === 'update'){
          formData.append('id', id);
      }
      
      formData.append('title', title);

      await api.post('/campanhas', formData);

      setLoading(false);
   
      history.push('/admin/campanhas');
   
    }
    catch(e){ 

      setLoading(false);
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    

      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
  
    }
  };

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/campanhas/${id}`);

      setTitle(response.data.title);
      setSubtitle(response.data.subtitle);
      setButtontext(response.data.buttontext);    
      setImagem(response.data.name);
      setUrl(response.data.url);

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/campanhas");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da Campanha</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              {!loading ? <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                <FileUploader
                    onFileSelectSuccess={(file) => { 
                            setSelectedFile(file);                     
                            setImagem(null); 
                            setRemoverImagem(false);
                    }}
                    onFileSelectError={({ error }) => addToast(error, {
                      appearance: 'error',
                      autoDismiss: true,
                    })}
                  />

                  {imagem ?
                  <>     
                    <hr />
                       <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/campanha/${imagem}`} height="250" />       
                    <hr />
                  </> : null}


                 <TextField
                      variant="outlined"
                      margin="normal"                   
                      fullWidth
                      id="title"
                      label="Titulo"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      
                  />                  
               
                </GridItem>               
              </GridContainer> :  <Box mt={6}><CircularProgress /></Box> }
             
            </CardBody>
            {!loading ? <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter> : null}
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
