import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(styles);

export default function Configurations() {

  const configurationID = '098b1f16-0b1e-4073-9772-1acb31167767';
  const [loaded, setLoaded] = useState(false);
  const [whatsapp, setWhatsapp] = useState();
  const [preco, setPreco] = useState(false);
  const [telefone, setTelefone] = useState();

  const [isLoading, setLoading] = useState(true);


  const formRef = useRef(null);
  const { addToast } = useToasts()
  const classes = useStyles();

  useEffect(() => {

  }, []);

  useEffect(() => {
    loadData();

  }, []);

  const handleSave = async (e) => {

    try {

      e.preventDefault();

      const id = configurationID;

      const schema = Yup.object().shape({
        telefone: Yup.string()
          .required('Informe o telefone'),
        whatsapp: Yup.string()
          .required('Informe o whatsapp')
      });

      await schema.validate({ telefone, whatsapp }, {
        abortEarly: false,
      });


      const postData = {
        id,
        whatsapp,
        telefone,
        preco
      };


      await api.post(`/configurations`, postData);


      addToast('Dados atualizados com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });

    }
    catch (e) {



      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }


      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }


  const loadData = useCallback(async () => {
    try {

      setLoading(true);
      const response = await api.get(`/configurations/${configurationID}`);
      setTelefone(response.data.telefone);
      setWhatsapp(response.data.whatsapp);
      setPreco(response.data.preco);
      setLoaded(true);
      setLoading(false);

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Configurações gerais</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados. Estes dados são para solicitação de criação de nova conta no aplicativo.</p>
              </CardHeader>
              <CardBody>

                {loaded ? <div>

                  <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                      <InputMask mask="(99) 999999999" maskPlaceholder="" value={telefone} onChange={(event) => setTelefone(event.target.value)}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="telefone"
                          label="Telefone"
                        />
                      </InputMask>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={12}>
                      <InputMask mask="(99) 999999999" maskPlaceholder="" value={whatsapp} onChange={(event) => setWhatsapp(event.target.value)}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="whatsapp"
                          label="Whatsapp"
                        />
                      </InputMask>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 24 }}>
                        <InputLabel id="category">Exibir preços dos produtos no aplicativo?</InputLabel>
                        <Select
                          labelId="preco"
                          label="Exibir preços dos produtos no aplicativo"
                          variant="outlined"
                          name="preco"
                          fullWidth
                          value={preco}
                          onChange={(e) => setPreco(e.target.value)}
                        >
                          <MenuItem key="S1" value={true}>Sim</MenuItem>
                          <MenuItem key="N1" value={false}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                  </GridContainer>

                </div> : isLoading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>
                ) : null}

              </CardBody>
              <CardFooter>
                {loaded ? <Button color="primary" type="submit">Salvar</Button> : null}
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>

    </div>
  );
}
