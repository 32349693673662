
import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';


import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


import api from '../../services/api';
const useStyles = makeStyles(styles);

export default function Stats() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [tipo, setTipo] = useState('D');
  const [dataInicial, setDataInicial] = useState(new Date(new Date() - (7 * 86400000)));
  const [dataFinal, setDataFinal] = useState(new Date());
  const { addToast } = useToasts()

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  const handlePrint = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.post("/stats/print", {
        filters: {
          start_date: dataInicial,
          end_date: dataFinal,
          type: tipo
        }},
        { responseType: 'blob'}
      ); 

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      link.setAttribute('download', `impressao_estatisticas.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();

      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro ', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [dataInicial, dataFinal, tipo]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      
      const response = await api.post("/stats/search", {
        filters: {
          start_date: dataInicial,
          end_date: dataFinal,
          type: tipo
        }
      }); 

      const result = response.data;
      const stats = [];

      result.stats.map(d => {
        stats.push({
           dispositivo: d.s_dispositivo,
           os: d.s_os,
           customer: d.s_customerId ? d.customer : 'Anônimo',
           created_at: d.s_created_at ? new Date(d.s_created_at).toLocaleString('pt-BR', { timeZone: 'UTC' }) : '-x-'
        });
        return d;
      })

      setTotal(result.total);
      setData(stats);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [dataInicial, dataFinal, tipo]);

  useEffect(() => {
    getData()
  },[])

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Estatisticas</h4>
            <p className={classes.cardCategoryWhite}>
              Estatisticas de Acesso ao Aplicativo
            </p>
          </CardHeader>
          <CardBody>


            <h4>Filtro</h4>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 20 }}>
                  <InputLabel id="type">Tipo Filtro</InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    id="tipo"
                    label="tipo cliente"
                    name="tipo"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                  >
                    <MenuItem key="D" value="D">Acessos distintos</MenuItem>
                    <MenuItem key="A" value="A">Todos acessos</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 15}} label="Periodo inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
           </MuiPickersUtilsProvider>

           <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 25}} label="Periodo final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
           </MuiPickersUtilsProvider>

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Filtrar</Button>

            <Button color="warning" type="button" style={{ marginTop: 20 }} onClick={handlePrint}>Imprimir</Button>

 
            <hr />
       

            {loading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <>
                <p><strong>TOTAL: </strong> {total}</p>
                </>
                {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Dispositivo', 'OS', 'Cliente', 'Acesso em ']}
                  tableColumn={['dispositivo', 'os', 'customer', 'created_at']}
                  tableColumnSize={['20%', '20%', '30%', '30%']}
                  tableData={data}
                  resource="stats"
                 /> : <p style={{ marginTop: 20 }}>Nenhum cliente cadastrado no momento.</p>}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
