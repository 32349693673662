
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

const stylesContainer = {
  lineHeight: '150px',
  backgroundColor: '#CACACA'
};

export default function DayOfferPage() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pictures, setPictures] = useState([]);
  const { addToast } = useToasts()

  useEffect(() => {
     getData();
  }, []);

 
  const getData = async () => {
    try {
      setLoading(true);
      const response = await api.get("/dayoffer?type=N");
      setLoading(false);
      setData(response.data);
    }
    catch(e){
      addToast(`Ocorreu um erro para atualizar os dados --> ${e.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });        
      setLoading(false);
    } 
  };

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover esta imagem ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/dayoffer/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Ofertas do Dia (Varejo)</h4>
            <p className={classes.cardCategoryWhite}>
              Ofertas do dia
            </p>
          </CardHeader>
         
          <CardBody>
    
          {data && data.length === 0 && <><Link to="/admin/dayoffer/new?type=N"> <Button color="info">Adicionar nova oferta</Button></Link><br /><br /></>}


          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Imagem', 'Titulo', '', '']}
              tableColumn={['dayoffer', 'title', 'edit', 'delete']}
              tableColumnSize={['40%', '30%', '1%', '1%']}
              tableData={data} 
              resource="dayoffer"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
            <CircularProgress />
            </Box>               
          ) : <p>Nenhuma oferta do dia cadastrada no momento.</p>}

           
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
