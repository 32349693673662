import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { makeStyles } from '@material-ui/core/styles';

import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';

import bgImage from 'assets/img/menu.jpg';
import logo from 'assets/img/logo-w.png';
import menuRoutes from '../../routes/menu.routes';

import EditCategoryPage from '../../pages/Categories/edit';
import EditSubcategoryPage from '../../pages/Subcategories/edit';

import EditAdminPage from '../../pages/Admin/edit';
import TextEditPage from '../../pages/Texts/edit';
import ProductEditPage from '../../pages/Products/edit';
import ProductImagesPage from '../../pages/ProductImages';
import EventImagesPage from '../../pages/EventsImages';
import EditCustomerPage from '../../pages/Customers/edit';
import CampanhasEditPage from '../../pages/Campanhas/edit';

import NotificationEditPage from '../../pages/Notifications/edit';

import BannersEditPage from '../../pages/Banners/edit';

import RegionEditPage from '../../pages/Regions/edit';

import DayOfferEditPage from '../../pages/DayOfferPage/edit';
import DayOfferAtacadoEditPage from '../../pages/DayOfferAtacadoPage/edit';
import DayOfferSnackEditPage from '../../pages/DayOfferPageSnack/edit';
import EventsEdit from '../../pages/Events/edit';

import VideosEdit from '../../pages/Videos/edit';

import EncarteEditPage from '../../pages/EncartePage/edit';


let ps;


const switchRoutes = (
  <Switch>
    <Route path="/admin/events/images/:id" isPrivate component={EventImagesPage} />
    <Route path="/admin/products/images/:id" isPrivate component={ProductImagesPage} />
    <Route path="/admin/categories/:id" isPrivate component={EditCategoryPage} />
    <Route path="/admin/customers/:id" isPrivate component={EditCustomerPage} />
    <Route path="/admin/subcategories/:id" isPrivate component={EditSubcategoryPage} />

    <Route path="/admin/administradores/:id" isPrivate component={EditAdminPage} />
    <Route path="/admin/texts/:id" isPrivate component={TextEditPage} />
    <Route path="/admin/products/:id" isPrivate component={ProductEditPage} />
    <Route path="/admin/banners/:id" isPrivate component={BannersEditPage} />
    <Route path="/admin/regions/:id" isPrivate component={RegionEditPage} />
    <Route path="/admin/dayoffer/:id" isPrivate component={DayOfferEditPage} />
    <Route path="/admin/dayofferatacado/:id" isPrivate component={DayOfferAtacadoEditPage} />
    <Route path="/admin/dayoffer_snack/:id" isPrivate component={DayOfferSnackEditPage} />
    <Route path="/admin/events/:id" isPrivate component={EventsEdit} />
    <Route path="/admin/videos/:id" isPrivate component={VideosEdit} />
    <Route path="/admin/encarte/:id" isPrivate component={EncarteEditPage} />
    <Route path="/admin/campanhas/:id" isPrivate component={CampanhasEditPage} />

    <Route path="/admin/customersPending/:id" isPrivate component={EditCustomerPage} />


    <Route path="/admin/notifications/:id" isPrivate component={NotificationEditPage} />

    {menuRoutes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}

  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();

  const [image,] = React.useState(bgImage);
  const [color,] = React.useState('blue');
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => window.location.pathname !== '/admin/maps';
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={menuRoutes}
        logoText="fortebeer"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={menuRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
