import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './hooks';

import Routes from './routes/index.routes';

import 'assets/css/material-dashboard-react.css?v=1.10.0';
 
ReactDOM.render(
  <BrowserRouter>
    <AppProvider> 
      <Routes />
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
