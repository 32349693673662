import React, {useState,  useCallback, useEffect} from 'react';
import api from '../../services/api';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../../assets/img/logo.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Forte Beer 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function TermoUso() {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [text, setText] = useState('');

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/texts/6da3278a-b27f-48de-b1f6-2b8dd3470111`);
      setName(response.data.title);
      setText(response.data.text);
    }
    catch(e){}
  },[]);

  useEffect(() => {    
      loadData();  
  },[]);

 

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>           
        <img src={Logo} style={{width: 150}} alt="logo" />
        <h3 style={{textAlign: 'center'}}>{name}</h3>   
        <p dangerouslySetInnerHTML={{__html: text}}></p>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}